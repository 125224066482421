@import "@variables"; @import "@mixin";
.menu {
  display: flex;
  flex-direction: row;
  align-items: center;

  > li {
    height: 34px;
    margin-right: 10px;
    border: 1px solid $theme-color;
    border-radius: 25px;
    &:hover {
      background-color: rgba($theme-color, 0.04);
    }
    &:active {
      background-color: rgba($theme-color, 0.08);
    }
    > a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0 20px;
      color: $theme-color;
      font-size: $font-12px;
      line-height: 24px;
    }

    &:last-child {
      background-color: $theme-color;

      &:hover {
        background-color: $theme-color-hover;
      }
      &:active {
        background-color: $theme-color-active;
      }
      > a {
        color: $white-1;
      }
    }
    &:last-child {
      margin-right: 0;
    }

    &.switchLang {
      background-color: transparent;
      height: auto;
      border: none;

      &:hover {
        background-color: transparent;
      }
    }
  }
}

.switchLang {
  &Block {
    position: relative;
  }

  &Button {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: $font-12px;

    &Text {
      margin: 0 6px 0 5px;
    }

    &Icon {
      width: 20px;
    }
  }

  &Box {
    position: absolute;
    top: 26px;
    left: 0;
    width: 101px;
    background-color: $white-1;
    border-radius: 6px;
    box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.24);
  }

  &List {
    &Item {
      padding: 8px 0;

      &:hover, 
      &Selected {
        background-color: $gray-1;
      }
    }
  }

  &Link {
    display: block;
    font-size: $font-12px;
    text-align: center;
  }
}

.logoutButton {
  display: flex;
  align-items: center;
  height: 34px;
  border: 1px solid $theme-color;
  border-radius: 25px;
  padding: 0 20px;
  color: $theme-color;
  font-size: $font-12px;
  cursor: pointer;
  &:hover {
    background-color: rgba($theme-color, 0.04);
  }
  &:active {
    background-color: rgba($theme-color, 0.08);
  }
}

@include screen-mobile {
  .menu {
    display: initial;
    flex-direction: initial;
    align-items: initial;
    > li {
      width: 100%;
      height: 44px;
      margin-right: initial;
      border-radius: 8px;
      margin-bottom: 10px;
      background-color: $white-1;
      > a {
        justify-content: center;
        font-size: $font-16px;
        font-weight: bold;
      }

      &.switchLang {
        display: none;
      }  
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  .logoutButton {
    justify-content: center;
    width: 100%;
    height: 44px;
    margin-right: initial;
    border-radius: 8px;
    margin-bottom: 10px;
    background-color: $white-1;
    font-size: $font-16px;
    font-weight: bold;
    &:hover {
      background-color: rgba($theme-color, 0.04);
    }
    &:active {
      background-color: rgba($theme-color, 0.08);
    }
  }
}
