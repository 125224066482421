@import "@variables"; @import "@mixin";
.stepper {
  display: flex;
  align-items: center;
}

.countDownButton,
.countUpButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: $blue-2;
  &:disabled {
    cursor: not-allowed;
    background-color: $blue-3;
  }
}

.count {
  width: 30px;
  color: $black-2;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  margin: 0 5px;
}
