// color
$theme-color: #0b6ab3;
$theme-color-filter: brightness(0) saturate(100%) invert(34%) sepia(26%) saturate(2547%) hue-rotate(175deg) brightness(94%) contrast(103%);
$theme-color-hover: #0a5b99;
$theme-color-active: #024579;

// colors
$gray-1: #f2f2f2;
$gray-2: #444;
$gray-3: #a7aaa8;
$gray-4: #e5e5e5;
$gray-5: #8e8e8e;
$gray-6: #8d8d8d;
$gray-7: #cacccd;
$gray-8: #d4d4d4;
$gray-9: #efefef;
$gray-10: #828282;
$gray-11: #ddd;
$gray-12: #6b6b6b;
$gray-13: #f8f8f9;
$gray-14: #f5f5f5;
$gray-15: #f1f1f1;
$gray-16: #c8c8c8;
$gray-17: #c4c4c4;
$gray-18: #4F4F4F;
$gray-19: #f3f3f3;
$gray-20: #767676;
$gray-21: #D4DDE4;
$gray-22: #A1AAB8;
$gray-23: #4D5E73;
$gray-24: #606060;
$gray-25: #C5C5C5;
$gray-26: #D5D5E9;
$gray-27: #AFC0CF;
$gray-28: #E4E4E4;
$white-1: #fff;
$black-1: #000;
$black-2: #333;
$black-3: #343537;
$black-4: #444444;
$red-1: #fdf2f2;
$red-2: #ef7b80;
$red-3: #d28584;
$red-4: #E2432A;
$blue-1: #007aff;
$blue-2: #5898e6;
$blue-3: #adcce3;
$blue-4: #eef5fc;
$blue-5: #f1f5fa;
$blue-6: #eff5f9;
$blue-7: #5eb0bb;
$blue-8: #5ABEB4;
$blue-9: #F6F9F9;
$blue-10: #CAD6DF;
$blue-11: #0066B5;
$blue-12: #1474fc;
$blue-13: #005BC5;
$blue-14: #DEEFFF;
$orange-1: #ffb443;
$orange-2: #fff7ec;
$orange-3: #ffa500;
$green-1: #f3fcef;
$green-2: #84dd63;
$pink-1: #EF7B80;
$yellow-1: #FFD700;

// font-size
$font-8px: 0.5rem;
$font-10px: 0.625rem;
$font-12px: 0.75rem;
$font-13px: 0.8125rem;
$font-14px: 0.875rem;
$font-15px: 0.9375rem;
$font-16px: 1rem;
$font-17px: 1.0625rem;
$font-18px: 1.125rem;
$font-20px: 1.25rem;
$font-21px: 1.3125rem;
$font-22px: 1.375rem;
$font-23px: 1.4375rem;
$font-24px: 1.5rem;
$font-25px: 1.563rem;
$font-26px: 1.625rem;
$font-28px: 1.75rem;
$font-32px: 2rem;
$font-33px: 2.0625rem;
$font-34px: 2.125rem;
$font-38px: 2.375rem;
$font-39px: 2.4375rem;
$font-40px: 2.5rem;
$font-42px: 2.625rem;
$font-46px: 2.875rem;
$font-48px: 3rem;
$font-55px: 3.4375rem;

// display size
$display-size-desktop-max: 3000;
$display-size-desktop-min: 992;
$display-size-tablet-max: 991;
$display-size-tablet-min: 576;
$display-size-mobile-max: 575;
$display-size-mobile-min: 0;

// breakpoint
$desktop-min: $display-size-desktop-min + px;
$tablet-max: $display-size-tablet-max + px;
$tablet-min: $display-size-tablet-min + px;
$mobile-max: $display-size-mobile-max + px;

:export {
  displaySizeDesktopMax: $display-size-desktop-max;
  displaySizeDesktopMin: $display-size-desktop-min;
  displaySizeTabletMax: $display-size-tablet-max;
  displaySizeTabletMin: $display-size-tablet-min;
  displaySizeMobileMax: $display-size-mobile-max;
  displaySizeMobileMin: $display-size-mobile-min;
  desktopMin: $desktop-min;
  tabletMax: $tablet-max;
  tabletMin: $tablet-min;
  mobileMax: $mobile-max;
  white1: $white-1;
}

// max-width
@mixin max-width-section {
  width: 100%;
  max-width: 1024px;
}
