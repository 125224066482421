@import "@variables"; @import "@mixin";
.section {
  @include max-width-section;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 32px 37px;
  margin: 41px auto 0;
  position: relative;

  &Heading {
    display: flex;
    flex-direction: column;
    align-items: center;

    &Title {
      font-size: $font-24px;
      font-weight: 700;
      line-height: 32px;
      margin-top: 10px;
      color: $theme-color;
    }
  }
}

.usage,
.review {
  background-color: $blue-4;

  &Title {
    color: $theme-color;
  }
}

.feature,
.cost {
  background-color: $orange-2;

  .section {
    &Heading {
      &Title {
        color: $orange-1;
      }  
    }   
  }
}

.location {
  &.section {
    max-width: 1120px;
    align-items: flex-start;
  }

  .section {
    &Heading {
      &Title {
        margin-top: 0;
        color: $black-3;
      }  
    }   
  }
}



@include screen-tablet {
  .reviewSection {
    padding: 32px;
  }
}

@include screen-mobile {
  .section {
    padding: 25px 16px;
    margin-top: 24px;
    border-radius: 0;
  }
}
