@import "@variables"; @import "@mixin";
.title {
  color: $black-3;
  margin-top: 0;
}

.locationsItems {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 11px;
  width: 100%;
  max-width: 900px;
  margin: 32px auto;

  &CountryTwn {
    margin-top: 0;
  }
}

.item {
  width: 100%;
  height: 184px;
  border-radius: 10px;

  &Overlay {
    width: 100%;
    height: 100%;
    background-color: rgba($white-1, 0.05);

    >a {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $font-25px;
      color: $white-1;
      width: 100%;
      height: 100%;
      line-height: 29px;
      font-weight: 700;
      cursor: pointer;
    }
  }
}

.areaItems {
  width: 100%;
  // max-width: 900px;
  box-sizing: border-box;
  margin: 24px auto;

  .areaItem {
    padding: 16px 0;
    border-top: 1px solid $gray-17;

    &:last-child {
      border-bottom: 1px solid $gray-17;
    }
  }

  &CountryTwn {
    display: none;
  }

  &Wrap {
    width: 100%;

    &Selected {
      display: none;
    }
  }
}

.selectedArea {
  width: 100%;
  margin-top: 24px;

  .backList {
    margin-bottom: 30px;

    .btnBack {
      color: $theme-color;
      font-size: $font-14px;
      font-weight: bold;
    }
  }

  .areaName {
    margin-bottom: 30px;

    &Link {
      font-weight: 700;
      font-size: $font-32px;
      color: $theme-color;
      line-height: 40px;
    }
  }

  .stationsContent {
    margin: 25px 0 0 0;
  }

  &Wrap {
    height: 0;
    overflow: hidden;

    &Selected {
      height: auto;
      overflow: visible;

      .selectedArea {
        margin-top: 30px;
      }
    }

    .selectedArea {
      margin-top: 0;
    }
  }
}

.prefectures {
  width: 100%;
  height: 0;
  overflow: hidden;

  &Selected {
    height: auto;
    overflow: visible;
  }
}

.gridLocationContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
}

.location {
  margin-bottom: 30px;
}

.area {
  font-weight: 700;
  color: #333;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 0;
}

.cityContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
}

.city {
  margin: 0;
  font-weight: 700;
  color: #0b6ab3;
  font-size: 14px;
  cursor: pointer;
}

@include screen-mobile {
  .locationsItems {
    max-width: initial;
    grid-template-columns: repeat(1, 1fr);

    &CountryTwn {
      display: none;
    }
  }

  .item {
    height: 130px;

    &Overlay {
      >a {
        font-size: $font-20px;
        line-height: 28px;
      }
    }
  }

  .areaItems {
    width: auto;
    max-width: initial;
    box-sizing: border-box;
    display: block;
    margin-top: 16px;

    .areaItem {
      min-width: initial;
      width: auto;
    }

    &CountryTwn {
      display: block;
    }
  }

  .selectedArea {
    margin-top: 20px;

    .areaName {
      &Link {
        font-size: $font-26px;
        line-height: 24px;
      }
    }

    &Wrap {
      &Selected {
        .selectedArea {
          margin-top: 20px;
        }
      }
    }
  }

  .gridLocationContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}