@mixin screen-desktop {
  @media screen and (min-width: $desktop-min) {
    @content;
  }
}

@mixin screen-tablet {
  @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
    @content;
  }
}

@mixin screen-mobile {
  @media screen and (max-width: $mobile-max) {
    @content;
  }
}

@mixin screen-tablet-mobile {
  @media screen and (max-width: $tablet-max) {
    @content;
  }
}
