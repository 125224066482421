@import "@variables"; @import "@mixin";
@mixin item-font {
  display: flex;
  align-items: center;
  color: $white-1;
  font-size: $font-15px;
  font-weight: 700;
  line-height: 17px;
}

.footer {
  background-color: $theme-color;
  padding: 34px 50px 41px;
  &Heading {
    width: 100%;
    margin-bottom: 11px;
    > img {
      max-width: 161px;
    }
  }
  &Contents {
    display: flex;
    justify-content: space-between;
  }
}

.list {
  margin-right: 68px;
  &Heading {
    display: flex;
    align-items: center;
    height: 42px;
    position: relative;
    &Title {
      color: $white-1;
      font-size: $font-20px;
      font-weight: 700;
      line-height: 23px;
    }
  }
  &Items {
    > li {
      @include item-font;
      height: 42px;
      > a {
        @include item-font;
        width: 100%;
        height: 100%;
      }
    }
  }
  &Other {
    &Heading {
      display: flex;
      align-items: center;
      height: 42px;
      position: relative;
      &Title {
        color: $white-1;
        font-size: $font-20px;
        font-weight: 700;
        line-height: 23px;
      }
    }
    &SnsItems {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 42px;
      margin-bottom: 19px;
      > li {
        margin-right: 22px;
        &:last-child {
          margin-right: initial;
        }
      }
    }
    &StoreItems {
      > li {
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: initial;
        }
      }
    }
  }
}

.openIntercom {
  cursor: pointer;
}

.accordion {
  display: none;
}

.snsIcon {
  filter: brightness(0) invert(1);
}

@include screen-mobile {
  .footer {
    padding: 25px 25px 33px 16px;
    &Heading {
      margin-bottom: 18px;
      > img {
        max-width: 144px;
      }
    }
    &Contents {
      justify-content: initial;
      flex-direction: column;
    }
  }

  .list {
    margin-right: initial;
    margin-bottom: 10px;
    &Heading {
      cursor: pointer;
    }
    &Other {
      max-width: 243px;
      margin: auto;
      &Heading {
        display: none;
      }
      &SnsItems {
        justify-content: space-between;
      }
      &StoreItems {
        display: flex;
        > li {
          margin-bottom: initial;
          margin-right: 9px;
          &:last-child {
            margin-right: initial;
          }
        }
      }
    }
  }

  .accordion {
    display: initial;
  }
}
