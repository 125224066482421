@import "@variables"; @import "@mixin";
.placeArea {
  margin: 0;
  background-color: $blue-4;
  padding: 24px;
  border-radius: 8px;

  &Title {
    font-size: $font-16px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 16px;
  }

  &ListWrap {
    margin: 0;
  }

  &List {
    display: flex;
    flex-wrap: wrap;
    gap: 12px 8px;

    &Item {
      > a {
        color: $theme-color;
        font-size: $font-14px;
        line-height: 16px;
        font-weight: 700;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
