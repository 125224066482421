@import "@variables"; @import "@mixin";
.accordionIcon {
  position: relative;
}

.close,
.open {
  &:before,
  &:after {
    content: '';
    width: 10px;
    height: 2px;
    background-color: $black-2;
    position: absolute;
    transform: translateY(-50%);
  }
}

.close::after {
  transform: translateY(-50%) rotate(90deg);
  transition: 0.3s;
}

.open::after {
  transform: rotate(0);
  transition: 0.3s;
}

@include screen-mobile {
  .accordionIcon {
    position: absolute;
    right: 40px;
  }
  
  .close,
  .open {
    &:before,
    &:after {
      content: '';
      width: 16px;
      height: 3px;
    }
  }

  .footer {
    &:before,
    &:after {
      background-color: $white-1;
    }
  }
}