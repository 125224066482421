@import "@variables"; @import "@mixin";
.main {
  height: 100%;
  transition: transform .3s;
  margin-top: 50px;
}

.openMenu {
  transform: translateX(185px);
}

@include screen-mobile {
  .main {
    transition: initial;
  }

  .openMenu {
    transform: initial;
  }
}