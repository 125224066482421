@import "@variables"; @import "@mixin";
.prefArea {
  margin: 0;

  &Title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &Text {
      font-weight: bold;
    }

    &Icon {
      min-width: 20px;
      min-height: 20px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  &ListWrap {
    height: 0;
    overflow: hidden;

    &Active {
      margin: 10px 0 0 0;
      height: auto;
    }
  }

  &List {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;

    &Item {
      >a {
        font-size: $font-14px;
        font-weight: 700;
        color: $theme-color;
      }
    }
  }

  &ArrowImage {
    width: 12px;
    transition: all .1s linear;

    &Active {
      transform: rotate(180deg);
    }
  }
}

.accordionIcon {
  width: 32px;
  height: 32px;
  position: relative;

  &::before,
  &::after {
    top: 50%;
    left: 50%;
    width: 18px;
    margin-left: -9px;
  }
}

.accordionIcon {
  width: 32px;
  height: 32px;
  position: relative;

  &::before,
  &::after {
    top: 50%;
    left: 50%;
    width: 18px;
    margin-left: -9px;
  }
}

@include screen-mobile {
  .prefArea {
    &Title {
      &Icon {
        min-width: 24px;
        min-height: 24px;
      }
    }
  }

  .accordionIcon {
    width: 24px;
    height: 24px;

    &::before,
    &::after {
      width: 14px;
      height: 2px;
      margin-left: -9px;
    }
  }
}