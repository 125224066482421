@import "@variables"; @import "@mixin";
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;

  &Box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 24px;
    border-bottom: 1px solid $gray-1;
    background-color: $white-1;
  }

  &Left {
    &Item {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.logo {
  width: 130px;
  cursor: pointer;
  margin-left: 28px;
}

.hamburgerMenu {
  position: relative;
  width: 22px;
  height: 16px;
  cursor: pointer;
  > span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $gray-2;
    border-radius: 3px;
    transition: 0.3s;
    &:first-of-type {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 7px;
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
  }
}

.open {
  > span {
    &:first-of-type {
      top: 5px;
      transform: rotate(135deg);
    }
    &:nth-of-type(2) {
      width: 0;
      left: 50%;
    }
    &:nth-of-type(3) {
      top: 5px;
      transform: rotate(-135deg);
    }
  }
}

@include screen-mobile {
  .header {
    justify-content: initial;
    position: fixed;
    top: 0;
    width: 100%;
    height: auto;
    border-bottom: 1px solid $gray-1;
    background-color: $white-1;
    z-index: 3;
    padding: 0;
    display: block;

    &Box {
      display: flex;
      padding: 0 24px;
      height: 50px;
    }
    &Left {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &Right {
      display: none;
    }
  }

  .hamburgerMenu {
    position: absolute;
    margin-right: initial;
    left: 24px;
  }

  .openSideMenu {
    background-color: $blue-4;
    border-bottom: initial;

    .headerBox {
      background-color: $blue-4;
    }
  }

  .hideLogo {
    display: none;
  }
}

.warningIcon {
  height: 16px;
  width: 16px;
  position: absolute;
  z-index: 1;
  top: -8px;
  right: -6px;
}